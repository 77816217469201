const likes = [
  'to code 👨🏻‍💻',
  'JS/TS 📜',
  'Linux 🐧',
  'the Web 🌐',
  'InfoSec 🕵🏻🔐',
  'Cyberpunk 🕶️💻',
  'the mountains 🏔️🌋',
  'rock climbing 🪨🧗🏼',
  'cycling 🚲',
  'Open Source 🔓ℹ️',
  'cinema 🎥',
  'cats 🐈',
  'plants 🌿🪴',
  'cycling 🚴',
  'cooking 🍳'
];

export default likes;
